<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <c-card title="수급업체 안전보건 협의체" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-appr-btn 
                v-if="!apprMode"
                ref="appr-btn"
                name="internal-action-appr-btn"
                :editable="editable"
                :approvalInfo="approvalInfo"
                @beforeApprAction="approvalAction"
                @callbackApprAction="approvalActionCallback"
                @requestAfterAction="research"
              />
              <!-- 삭제 -->
              <c-btn v-show="editable&&!disabled&&isOld&&!apprMode" label="LBLREMOVE" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
              <!-- 저장 -->
              <c-btn
                v-show="editable && !disabled" 
                :url="saveUrl"
                :isSubmit="isSave"
                :param="tabParam"
                :mappingType="mappingType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 제목 -->
              <c-text
                required
                :disabled="disabled"
                :editable="editable"
                label="LBLTITLE"
                name="councilTitle"
                v-model="tabParam.councilTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 일자 -->
              <c-datepicker
                required
                :editable="editable"
                :disabled="disabled"
                label="LBL0003064"
                name="councilDate"
                v-model="tabParam.councilDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 구분 -->
              <c-select
                required
                type="edit"
                :disabled="disabled"
                codeGroupCd="COUNCIL_TYPE_CD"
                itemText="codeName"
                itemValue="code"
                name="councilTypeCd"
                label="LBL0003024"
                v-model="tabParam.councilTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                required
                :editable="editable"
                :disabled="disabled"
                type="edit"
                name="plantCd"
                v-model="tabParam.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-dept
                :required="true"
                :disabled="disabled"
                :editable="editable"
                label="작성부서"
                name="deptCd"
                v-model="tabParam.deptCd">
              </c-dept>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!-- 내용 -->
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="4"
                label="LBL0003068"
                name="councilContents"
                v-model="tabParam.councilContents">
              </c-textarea>
            </div>
          </template>
        </c-card>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- 내부참석자 목록 -->
            <c-table
              ref="inTable"
              title="LBL0003069"
              :columns="inGrid.columns"
              gridHeight="250px"
              :data="tabParam.attendeeInModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :hideBottom="true"
              :editable="editable"
              :checkClickFlag="false"
              :isExcelDown="false"
              rowKey="councilAttendeeId"
              selection="multiple"
            >
              <template slot="table-button">
                <q-btn-group outline>
                  <!-- 추가 -->
                  <c-btn v-if="editable&&!disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addInAttendee" />
                  <!-- 제외 -->
                  <c-btn v-if="editable && tabParam.attendeeInModels.length > 0&&!disabled" :showLoading="false"  label="LBLEXCEPT" icon="remove" @btnClicked="removeInAttendee" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
          <template>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-md">
              <c-upload 
                :attachInfo="attachInfo"
                :disabled="disabled"
                :editable="editable">
              </c-upload>
            </div>
          </template>
        </div>  
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- 대상 협력업체 -->
            <c-table
              ref="vendorTable"
              title="LBL0003071"
              :columns="outVendorGrid.columns"
              gridHeight="250px"
              :data="tabParam.coucilVendorModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :hideBottom="true"
              :checkClickFlag="false"
              :isExcelDown="false"
              :editable="editable"
              rowKey="vendorCd"
              selection="multiple"
            >
              <template slot="table-button">
                <q-btn-group outline>
                  <!-- 추가 -->
                  <c-btn v-if="editable && !disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addVendor" />
                  <!-- 제외 -->
                  <c-btn v-if="editable && tabParam.coucilVendorModels.length > 0 && !disabled" :showLoading="false"  label="LBLEXCEPT" icon="remove" @btnClicked="removeVendor" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- 외부참석자 목록 -->
            <c-table
              ref="outTable"
              title="LBL0003072"
              :columns="outGrid.columns"
              gridHeight="250px"
              :data="tabParam.attendeeOutModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :hideBottom="true"
              :checkClickFlag="false"
              :isExcelDown="false"
              :editable="editable"
              rowKey="councilAttendeeId"
              selection="multiple"
            >
              <template slot="table-button">
                <q-btn-group outline>
                  <!-- 추가 -->
                  <c-btn v-if="editable && !disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addOutAttendee" />
                  <!-- 제외 -->
                  <c-btn v-if="editable && tabParam.attendeeOutModels.length > 0 && !disabled" :showLoading="false"  label="LBLEXCEPT" icon="remove" @btnClicked="removeOutAttendee" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'council-detail',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        councilId: '',
        plantCd: '',
        deptCd: '',
        councilDate: '',
        completeFlag: 'N',
        councilContents: '',
        councilTitle: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        councilTypeCd: null,
        attendeeInModels: [],
        attendeeOutModels: [],
        deleteAttendeeInModels: [],
        deleteAttendeeOutModels: [],
        coucilVendorModels: [],
        deletecoucilVendorModels: [],

        regUserId: '',
        chgUserId: '',
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SOP_COUNCIL',
        taskKey: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    apprMode: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      attachable: false,
      saveUrl: transactionConfig.mdm.cim.vendor.council.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isApproval: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      updateMode:false,
      realKey: '',
      inGrid: {
        columns: [
          {
            name: 'attendeeDeptName',
            field: 'attendeeDeptName',
            // 부서
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'attendeeUserName',
            field: 'attendeeUserName',
            // 이름
            label: 'LBLNAME',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'attendeeJobName',
            field: 'attendeeJobName',
            // 직책
            label: 'LBLPOSITION',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            type: 'textarea',
            align: 'left',
            style: 'width: 200px',
          },
        ],
        data: [],
      },
      outVendorGrid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            // 협력업체
            label: 'LBL0003073',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            type: 'textarea',
            align: 'left',
            style: 'width: 60%',
          },
        ],
        data: [],
      },
      outGrid: {
        columns: [
          {
            name: 'attendeeBelong',
            field: 'attendeeBelong',
            // 소속
            label: 'LBL0003074',
            type: 'text',
            align: 'center',
            style: 'width: 80px',
            required: true,
          },
          {
            name: 'attendeeUserName',
            field: 'attendeeUserName',
            // 이름
            label: 'LBLNAME',
            style: 'width: 80px',
            type: 'text',
            required: true,
            align: 'center',
          },
          {
            name: 'attendeeJobName',
            field: 'attendeeJobName',
            // 직책
            label: 'LBLPOSITION',
            type: 'text',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            type: 'textarea',
            align: 'left',
            style: 'width: 200px',
          },
        ],
        data: [],
      },
    };
  },
  computed: {
    isOld() {
      return Boolean(this.tabParam.councilId);
    },
    apprEditable() {
      return this.isOld
        && this.tabParam.completeFlag === 'N'
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.tabParam.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.tabParam.approvalStatusCd, 
        apprEditable: this.apprEditable,
        param: this.action, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000032', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          councilId: this.tabParam.councilId,
          isApprContent: true
        },
        approvalRequestName: this.tabParam.councilTitle, // 결재요청명 (문서 title)
        approvalConnId: this.tabParam.councilId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      // url setting
      this.insertUrl = transactionConfig.mdm.cim.vendor.council.insert.url;
      this.updateUrl = transactionConfig.mdm.cim.vendor.council.update.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.council.delete.url;
    },
    research() {
      this.$emit('getDetail')
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.tabParam.regUserId = this.$store.getters.user.userId
        this.tabParam.chgUserId = this.$store.getters.user.userId
        resolve(true);
      });
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveData() {
      if (this.tabParam.councilId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.outGrid.columns, this.tabParam.attendeeOutModels)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.saveValue
                },
              ];
              this.$comm.orderedPromise(promises);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.councilId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.tabParam.councilId) {
        this.$set(this.attachInfo, 'taskKey', this.tabParam.councilId)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.tabParam.councilId = result.data
      this.$emit('changeStatus', result.data)
      this.$emit('getDetail');
    },
    approvalAction() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.approvalValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approvalActionCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    addInAttendee() {
      this.popupOptions.title = "LBL0003075"; // 내부참석자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;

    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.attendeeInModels, {
            attendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.attendeeInModels.splice(0, 0, {
              councilId: this.tabParam.councilId,
              councilAttendeeId: uid(),
              attendeeType: '1',
              attendeeUserId: _item.userId,
              attendeeUserName: _item.userName,
              attendeeDeptName: _item.deptName,
              attendeeJobName: _item.jobName,
              remark: '',
              chgUserId: this.$store.getters.user.userId,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeInAttendee() {
      let selectData = this.$refs['inTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.tabParam.deleteAttendeeInModels) {
            this.tabParam.deleteAttendeeInModels = []
          }
          if (this.$_.findIndex(this.tabParam.deleteAttendeeInModels, { councilAttendeeId: item.councilAttendeeId }) === -1
            && item.editFlag !== 'C') {
              this.tabParam.deleteAttendeeInModels.push(item)
          }
          this.tabParam.attendeeInModels = this.$_.reject(this.tabParam.attendeeInModels, item)
        })
      }
    },
    addOutAttendee() {
      this.tabParam.attendeeOutModels.splice(0, 0, {
        councilId: this.tabParam.councilId,
        councilAttendeeId: uid(),
        attendeeType: '2',
        attendeeUserId: '',
        attendeeUserName: '',
        attendeeJobName: '',
        remark: '',
        attendeeBelong: '',
        chgUserId: this.$store.getters.user.userId,
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeOutAttendee() {
      let selectData = this.$refs['outTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.tabParam.deleteAttendeeOutModels) {
            this.tabParam.deleteAttendeeOutModels = []
          }
          if (this.$_.findIndex(this.tabParam.deleteAttendeeOutModels, { councilAttendeeId: item.councilAttendeeId }) === -1
            && item.editFlag !== 'C') {
              this.tabParam.deleteAttendeeOutModels.push(item)
          }
          this.tabParam.attendeeOutModels = this.$_.reject(this.tabParam.attendeeOutModels, item)
        })
      }
    },
    addVendor() {
      this.popupOptions.title = "LBL0003076"; // 협력업체 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeVendorPopup;

    },
    closeVendorPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.coucilVendorModels, {
            vendorCd: _item.vendorCd,
          });
          if (index === -1) {
            this.tabParam.coucilVendorModels.push({
              councilId: this.tabParam.councilId ? this.tabParam.councilId : uid(),
              vendorCd: _item.vendorCd,
              vendorName: _item.vendorName,
              remark: '',
              chgUserId: this.$store.getters.user.userId,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeVendor() {
      let selectData = this.$refs['vendorTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.tabParam.deletecoucilVendorModels) {
            this.tabParam.deletecoucilVendorModels = []
          }
          if (this.$_.findIndex(this.tabParam.deletecoucilVendorModels, { vendorCd: item.vendorCd }) === -1
            && item.editFlag !== 'C') {
              this.tabParam.deletecoucilVendorModels.push(item)
          }
          this.tabParam.coucilVendorModels = this.$_.reject(this.tabParam.coucilVendorModels, item)
        })
      }
    },
  }
};
</script>
